<template>
  <div>
    <Modal :title="titleModal" :width="widthModal" v-if="showModal('print')" :onHideModal="onHideModal">
      <PrintDetail :module="module" :documentId="documents" :listBillet="listBillet" :rentId="rentId"
        :periodRentId="periodRentId" :movimentId="movimentId" :invoiceId="invoiceId"
        :payableReceivableId="payableReceivableId" :orderServiceId="orderServiceId"
        :canModifyDocument="canModifyDocument">
        <div slot="top">
          <slot name="top"></slot>
        </div>
        <slot></slot>
      </PrintDetail>
    </Modal>
    <div v-if="!modal">
      <PrintDetail :module="module" :documentId="documents" :listBillet="listBillet" :rentId="rentId"
        :periodRentId="periodRentId" :movimentId="movimentId" :invoiceId="invoiceId"
        :payableReceivableId="payableReceivableId" :orderServiceId="orderServiceId"
        :canModifyDocument="canModifyDocument">
        <div slot="top">
          <slot name="top"></slot>
        </div>
        <slot></slot>
      </PrintDetail>
    </div>
  </div>
</template>

<script>


import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import PrintDetail from './PrintDetail.vue'

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PrintPreview",
  components: {
    Button,
    Molded,
    Modal,
    PrintDetail
  },
  props: {
    module: String,
    rentId: String,
    periodRentId: String,
    movimentId: String,
    invoiceId: String,
    payableReceivableId: String,
    orderServiceId: String,
    onHideModal: Function,
    canModifyDocument: Boolean,
    listBillet: {
      type: Array,
      default: []
    },
    modal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      titleModal: "Selecione o Documento",
      widthModal: 800,
    };
  },
  mounted() {
    if (this.modal)
      this.openModal("print");
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "removeLoading"]),
    print() {
      this.openModal("print");
      this.removeLoading(["btnPrint"]);
    },
    preview() {
      this.titleModal = "Impressão";
      this.widthModal = 1200;
    },
    mail() {
      this.titleModal = "E-mail";
      this.widthModal = 900;
    },
    billet() {
      this.titleModal = "Boleto Bancário";
      this.widthModal = 1000;
    },
    signature() {
      this.titleModal = "Assinatura Digital";
      this.widthModal = 1000;
    },
    back() {
      this.titleModal = "Selecione o Documento";
      this.widthModal = 800;
      this.removeLoading(["back"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "btnClickedNext") {
          if (event.data.type == "view") this.preview();
          if (event.data.type == "mail") this.mail();
          if (event.data.type == "billet") this.billet();
          if (event.data.type == "signature") this.signature();
        }

        if (event.name == "emailSent" || event.name == "back") {
          this.back();
        }


      },
      deep: true,
    },
  },
};
</script>
